import React from 'react'

const Token = () => {
  return (
    <div className='min-h-[100vh] w-[100vw] bg-main py-10'>
            <h1 class="title-font text-[40px] sm:text-[80px] md:text-[80px] font-bold mb-4  text-[#5A3402] text-center">TOKENOMICS</h1>
            <div className='py-10  flex flex-col items-center'>
                <div className='flex flex-col items-center my-6'>
                    <img className='max-w-[300px] mb-4' src={require('../Assets/t1.png')}/>
                    <p className='text-[#383838] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>1 Billion Supply</p>
                </div>
                <div className='flex flex-col items-center my-6'>
                    <img className='max-w-[200px] mb-4' src={require('../Assets/t2.png')}/>
                    <p className='text-[#383838] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>Renounced</p>
                </div>
                <div className='flex flex-col items-center my-6'>
                    <img className='max-w-[200px] mb-4' src={require('../Assets/t3.png')}/>
                    <p className='text-[#383838] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>LP Burned</p>
                </div>
                <div className='flex flex-col items-center my-6'>
                    <img className='max-w-[200px] mb-4' src={require('../Assets/t4.png')}/>
                    <p className='text-[#383838] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>No Taxes</p>
                </div>
                
            </div>
    </div>
  )
}

export default Token