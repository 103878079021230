import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#FFE4C1] w-[100vw] '>
            <Marquee>
                <div className='h-[90px] md:h-[140px] flex justify-center items-center gap-8 md:gap-32'>
                    <div className='flex items-center gap-8 md:gap-10'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../Assets/s1.png')} />
                        
                    </div>
                </div>
            </Marquee>
        </div>
    )
}

export default Slider