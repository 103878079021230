import React from 'react'

const Home = () => {
    return (
        <div className='min-h-[100vh] w-[100vw] bg-main py-10'>
            <h1 class="title-font text-[40px] sm:text-[80px] md:text-[80px] font-bold mb-4  text-[#5A3402] text-center">The First Dog on IG - $TEST</h1>
            <p className='text-[#be7e29] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>The first ever post on Instagram is also the first ever dog on Instagram! This is the $TEST dog, the first coin of its kind.</p>
            <div className='flex w-full justify-center mt-[50px] items-center'>
                <a href="">
                    <img className='w-[900px] min-w-[300px]' src={require('../Assets/article.png')} />
                </a>
            </div>
            <div className='flex flex-row w-full justify-center gap-6 mt-[50px] items-center pb-8'>
                <a href='https://x.com/TestDogIG'>
                    <img className='w-[100px] ' src={require('../Assets/tw.png')} />
                </a>
                <a href='https://t.me/firstigdog'>
                    <img className='w-[100px] ' src={require('../Assets/tg.png')} />
                </a>
            </div>
        </div>
    )
}

export default Home