import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Slider from './Components/Slider';
import Token from './Components/Token';

function App() {
  return (
    <div className='max-w-[100vw] overflow-clip'>
      <Home/>
      <Slider/>
      <Token/>
      <Slider/>

      </div>
  );
}

export default App;
